@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    
  }
  
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    
  }
  .spin{
   text-align: center;
  }
  .table-container {
    max-width: 100%;
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }
  
  .table th,
  .table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .table th {
    background-color: #f2f2f2;
    font-weight: bold;
    white-space: nowrap;
  }
  
  .table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  @media screen and (max-width: 600px) {
    .table th,
    .table td {
      padding: 8px;
      font-size: 14px;
    }
  }
  