.instructions-container{
width: 100%;
/* height: 80vh; */
}
.instructions{
    width: 70%;
    margin: 1rem auto;
    text-align: center;

}
.instructions h2{
    
    margin-top: 1rem;
    text-transform: uppercase;


}
.instructions a{
    
   color: #77B6FF;
   text-decoration: none;


}
.instructions-p{
    text-align: center;
font: normal normal normal 20px/40px Lato;
letter-spacing: 0px;
color: #000000;
}
.users-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    width: 100%;
    margin: 2rem 0;
  }
     /* CSS styles for the table */
     table {
      border-collapse: collapse;
      width: 100%;
    }
    
    th, td {
      padding: 8px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }
    
    th {
      background-color: #f2f2f2;
    }
  
  @media (max-width: 1200px) {
    .users-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .users-grid {
      grid-template-columns: 1fr;
    }
  }
  
  .user-card {
    background-color:white;
    width: 100%;
    height: 160px;
    padding: 10px;
    border-radius: 10px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  }
  .card-header{
    background-color: #04049c;
    color: white;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card-header h3{
    color: white;
    text-align: center;
  }
  .card-body{
    padding-top: 10px;
  }
  .user-card span{
    font-weight: 800;
  }