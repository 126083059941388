*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.terms{
    text-align: center;
    font-size: large;
    margin: 0 20px;
}
ul{
    text-align: left;
    letter-spacing: 1px;
}
.user-grid{
    margin: 15px;
}
.marquee {
    width: 100%;
    background: rgb(123, 8, 39);
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
  }
  .reprint{
    padding: 15px;
    font-size: 30px;
    text-decoration: none;
    background: #000066;
    color: white;
    border-radius: 50px;
}
.verify{
    padding: 15px;
    margin: 5px;
    font-size: 30px;
    text-decoration: none;
    background: #660014;
    color: white;
    border-radius: 50px;
}
  .marquee div {
    font-size: 1rem;
    font-family: verdana;
    padding-left: 100%;
    display: inline-block;
    animation: animate 30s linear infinite;
  }
  .marquee:hover div{
    animation-play-state: paused;
  }
  
  @keyframes animate {
    100% {
      transform: translate(-100%, 0);
    }
  }
.container{
    width: 100%;
    max-width: 2200px;
}
.nav-container{
    width: 100%;height: 100px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
opacity: 1;
    
}
.nav{
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    
}
.pagination{
    font-size: 20px;
    background-color: #000066;
    color: #fff;
    height: 50px;
}
.pagination1{
    height: 50px;
    width: 30px;
   border: 2px solid white;
   background-color: #000066 ;
   color: white;
}
.pagination2{
    height: 50px;
    width: 30px;
   border: 2px solid white;
 
}
.nav-image{
    display: flex;
    width: 25%;
    padding-top: 0.5rem;
}
.logo{
    width: 100%;
}
.logo img{
    width: 100%;
}
.search{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 15px;
}
.nav-logo-text{
    border-left: 1px solid grey;
    padding-left: 0.5rem;
    margin-left: 0.5rem;
    color: #000066;
}
.nav-download{

/* height: 64px; */
color: white;
display: flex;
justify-content: center;
align-items: center;
text-align: left;
font: normal normal normal 37px/56px Poppins;
letter-spacing: 0px;
cursor: pointer;
text-transform: uppercase;
opacity: 1;
background: #000066 0% 0% no-repeat padding-box;
opacity: 1;
}
.nav-download1{

    /* height: 64px; */
    color: white;
   
    text-align: left;
    font: normal normal normal 18px/16px Poppins;
    letter-spacing: 3px;
    cursor: pointer;
    text-transform: uppercase;
    opacity: 1;
    background: #000066 0% 0% no-repeat padding-box;
    opacity: 1;
    }
    .nav-download1 p{

    /* height: 64px; */
    margin: 7px;
    
    }
.publish-container{
    width: 90%;
    margin: 2rem auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.publish{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.publish-section-header{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}
.publish-section-footer{
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
margin: 1rem auto;
}
.publish-section-footer p{
    text-align: center;
font: normal normal normal 25px/50px Lato;
letter-spacing: 0px;
color: #000000;
opacity: 1;
padding-top: 0;

}
.publish-section-footer img{
    width: 50%;
}
.publish-content{
    width: 80%;
    display: flex;
    margin-top: 1rem;
    align-items: flex-start;
    justify-content: flex-start;
}
.publish-form{
    width: 50%;
}
.publish-form1{
    width: 60%;

}
.publish-header{
    text-align: left;
font: normal normal 900 50px/60px Lato;
letter-spacing: 0px;
color: #000000;
opacity: 1;
}
.publish-content p{
    text-align: left;
font: normal normal normal 25px/50px Lato;
letter-spacing: 0px;
color: #000000;
opacity: 1;
padding-top: 0;
margin-left: 1rem;
}
.publish-content i{
    color: #ED3237 ;
opacity: 1;
margin-top: 1rem;

}
.publish-form{
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: -5px 10px 20px #0000002E;
border-radius: 54px;
opacity: 1;
width: 45%;
margin-left:  auto;
}
.form-box{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 90%;
    margin: 1rem auto;
}
.form-box1{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 90%;
    margin: 1rem auto;
}
 .form-box p{
    text-align: left;
font: normal normal normal 25px/44px Lato;
letter-spacing: 0px;
color: #000000;
opacity: 1;
margin-top: 1rem;
} 
.form-box1 p{
    text-align: center;
font: normal normal normal 15px/24px Lato;
letter-spacing: 0px;
color: #000000;
opacity: 1;
margin-top: 1rem;
} 
.form-box-input{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000066;
    border-radius: 42px;
    opacity: 1;
    height: 60px;
    margin-top: 0.4rem;
    padding: 1rem;
    font-size: 1.2rem;
}
.form-box-input1{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000066;
    border-radius: 42px;
    opacity: 1;
    height: 30px;
    margin-top: 0.4rem;
    padding: 1rem;
    font-size: 1.2rem;
}
.form-box-input2{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000066;
    border-radius: 42px;
    opacity: 1;
    height: 60px;
    margin-top: 0.4rem;
    padding: 1rem;
    font-size: 1.2rem;
}

.file-input{
    padding-left: 1rem;
    font-size: 1.1rem;
    margin-top: 0.1rem;

}
.form-box select{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000066;
    border-radius: 42px;
    opacity: 1;
    height: 60px;
    margin-top: 0.4rem;
}
.form-box option{
    text-align: left;
    font: normal normal normal 20px/30px Lato;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
}
.my-select{
    
    font: normal normal normal 20px/30px Lato;
    letter-spacing: 0px;
    color: #000000;
    padding: 0 1rem;
    opacity: 1;
}
.form-box i{
    font-size: 2rem;
    color: red;
    float: right;
    margin-top: 1rem;
    margin-right: 1rem;
}
.form-box label{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000066;
    border-radius: 42px;
    opacity: 1;
    height: 60px;
    margin-top: 0.4rem;
}

.form-box-asterix{
    font-size: 1.5rem;
    color: red;
}
.form-box-amount{
    padding-left: 1rem;
}
.form-box button{
    background: #000066 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #000066;
border-radius: 50px;
opacity: 1;
width: 50%;
margin: 1.5rem auto;
text-align: center;
font: normal normal bold 45px/57px Lato;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
}
.form-box small{
    text-align: right;
font: italic normal normal 17px/44px Lato;
letter-spacing: 0px;
color: #000000;
opacity: 1;
}
.footer-container{
    height: 100px;
    background: #000066 0% 0% no-repeat padding-box;
opacity: 1;
width: 100%;
}
.footer{
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}
.footer-header{
    color: white;
    width: 35%;
}
.footer-content{
    display: flex;
    align-items: center;
    width: 65%;
    justify-content: space-around;
}
.footer-content a{
    
    text-decoration: none;
    font: normal normal normal 23px/39px Lato;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
}
.download-boxes{
    width: 70%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 70vh;
    
}
.download{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.card{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.card-top{
    height: 240px;
    width: 100%;
    background-color: #eee;
    background-image: url(../assests/logo.png);
    background-repeat: no-repeat;
    background-size: contain;
 
}
.card-bottom{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 70px;
    border: 1px solid #eee;
   
}
.card-bottom i {
    font-size: 1.5rem;
    cursor: pointer;

}
.card-bottom p{
    font-size: 1.0rem;
}
.download-box{
    width: 25%;
    margin-top: 2rem;
  
   

}
.image-container{
    height: 70vh;
    /* width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.image-container1{
    height: 70vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.image-nin{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
}
.download-nin{
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.image-input{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000066;
    border-radius: 42px;
    border: 0;
    opacity: 1;
    width: 805;
    height: 60px;
    margin-top: 0.4rem;
    padding: 1rem;
    font-size: 1.2rem;
    margin: 1rem auto;
}
.image-submit{
background: #000066 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #000066;
border-radius: 50px;
opacity: 1;
width: 30%;
margin-top: 4rem;
text-align: center;
font: normal normal bold 30px/25px Lato;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
margin-bottom: 1rem;
cursor: pointer;

}
.nav-download{
    width: 100%;
    height: auto;
    text-align: center;
    border-radius: 30px;
}
@media screen and (max-width:900px){
    .publish-container{
        flex-direction: column;

    }
    .image-container1{
        height: 70vh;
        /* width: 100%; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .publish-content p{
        text-align: left;
    font: normal normal normal 20px/30px Lato;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding-top: 0;
    margin-left: 1rem;
    }
    .publish-header{
        text-align: left;
    font: italic italic 900 30px/40px Lato;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    
    }
    .download-nin{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        margin-bottom: 10px;
    }
    .publish{
        width: 100%;
    }
    .publish-form{
        width: 100%;
    }
    .nav-container{
        height: auto;
    }
    .nav{
        flex-direction: column;
    }
    .download-boxes{
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 70vh;
        
    }
    .nav-image{
        width: 100%;
        margin-bottom: 3rem;
        
    }
    .nav-download{
        width: 100%;
        height: auto;
        text-align: center;
        border-radius: 30px;
        margin: auto !important;

    }
    .reprint{
        padding: 15px;
        font-size: 30px;
        text-decoration: none;
        background: #000066;
        color: white;
        border-radius: 50px;
    }
    .form-box button {
        font: normal normal bold 35px/47px Lato;
    }
    .footer-container{
        height: auto;
    }
    .footer{
        flex-direction: column;
    }
    .footer-header{
        width: 100%;
    }
    .footer-content{
        width: 100%;
        margin-top: 1rem;
        flex-direction: column;
        align-items: flex-start;
    }
    .card-top{
        height: 120px;
        width: 100%;
        background-color: #eee;
        background-image: url(../assests/logo.png);
        background-repeat: no-repeat;
        background-size: contain;
     
    }
    .image-container{
        height: 100% !important;
        width: 100% !important;
        display: block;
        justify-content: center;
        align-items: center;
    }
    .nav-download{
        width: 100%;
        height: auto;
        text-align: center;
        border-radius: 30px;

    }
    .image-submit{
        background: #000066 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #000066;
        border-radius: 50px;
        opacity: 1;
        width: 50%;
        margin-top: 4rem;
        text-align: center;
        font: normal normal bold 35px/40px Lato;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        margin-bottom: 1rem;
        
        }
   
}